@import url('https://rsms.me/inter/inter.css');
*,
*:before,
*:after {
  font-feature-settings: 'pnum' 1, 'lnum' 1, 'cv11' 1;
  font-family: 'Inter var', sans-serif;
  box-sizing: border-box;
  font-weight: normal;
  font-style: normal;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  line-height: 18px !important;
}
ol,
ul {
  list-style: none;
}

#root {
  position: relative;
  min-height: 100%;
  width: 100%;
  background: none;
  height: 100%;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  scrollbar-width: none; /* Firefox 64+ */
  -ms-overflow-style: none; /* Internet Explorer 11+ */
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 80%;
  }
}

.checkout-form {
  background-color: #fafafa;
  height: auto;
  border-radius: 5px;
  width: 35%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  display: none;
  position: absolute;
  bottom: 100%;
  right: -30px;
  margin-bottom: 5px;
  background-color: #121212;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}

.tooltip-text::before {
  content: '';
  position: absolute;
  bottom: -10.3px;
  right: 30px;
  border-width: 6px;
  border-style: solid;
  border-color: #121212 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}
.custom-btn {
  border: 1px solid #000000 !important;
}
.change-btn {
  border: 1px solid #eeeeee !important;
}

/* Loader styles */
.progress-container {
  position: relative;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
}

.background-image {
  position: absolute;
  z-index: 0;
}

.loader {
  width: 70px;
  height: 70px;
  border: 3px solid #eeeeee;
  border-bottom-color: #ffcc00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  z-index: 1;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Loader styles end */

.subtext {
  color: #999999 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progressbar-container {
  background-color: #eeeeee;
  border-radius: 8px;
  height: 8px;
  width: 100%;
}

.progressbar {
  background-color: #ffe580;
  border-radius: 8px;
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.help-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.help-container:hover .help-text {
  visibility: visible;
}

#klump__checkout {
  display: none;
}
